import type { ReactNode } from 'react';

import { CookieModal } from '@endaoment-frontend/ui/shared';
import { Footer, SuperHeader } from '@endaoment-frontend/ui/smart';

import { NavBar } from './NavBar';

export const PageLayout = ({ children }: { children: Array<ReactNode> | ReactNode }) => {
  return (
    <>
      <NavBar />
      <main className='app'>{children}</main>
      <Footer />
      <CookieModal />
      <SuperHeader />
    </>
  );
};
